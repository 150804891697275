import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlus, faEnvelope, faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import '../../App.css'

import { authAxios } from '../../scripts/Axios'
import { AppContext } from '../../scripts/AppContext'
import { UserContext } from '../../scripts/UserContext'
import { DataContext } from '../../scripts/DataContext'
import { ContactType } from '../../scripts/Functions'

import Window from '../../components/Window'
import Table from '../../components/Table'
import Modal from '../../components/Modal'
import CareTimeline from '../../components/CareTimeline'
import Selectbox from '../../components/Selectbox'
import Alert from '../../components/Alert'

function ProfileCaresScreen({user, setUser, userBadges}) {

    const { setLoading } = useContext(AppContext)
    const { checkScope, user: admin } = useContext(UserContext)
    const { countries, systemTemplates } = useContext(DataContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [targetUsers, setTargetUsers] = useState([])

    const [editCareID, setEditCareID] = useState(null)
    const [addCareInputs, setAddCareInputs] = useState(null)
    const [editCareInputs, setEditCareInputs] = useState(null)

    const [addCareModal, setAddCareModal] = useState(false)
    const [addCareModalCheck, setAddCareModalCheck] = useState(false)
    const [editCareModal, setEditCareModal] = useState(false)
    const [updateCareModal, setUpdateCareModal] = useState(false)

    const [showCareInfoModal, setShowCareInfoModal] = useState(false)
    const [careInfoInputs, setCareInfoInputs] = useState(null)

    const [editTravelID, setEditTravelID] = useState(null)
    const [addTravelInputs, setAddTravelInputs] = useState(null)
    const [editTravelInputs, setEditTravelInputs] = useState(null)

    const [addTravelModal, setAddTravelModal] = useState(false)
    const [editTravelModal, setEditTravelModal] = useState(false)

    const [matchingModal, setMatchingModal] = useState(false)

    const [timeline, setTimeline] = useState({
        year: moment().year()
    })
    const years = [moment().year()+1, moment().year(), moment().year()-1, moment().year()-2, moment().year()-3, moment().year()-4, moment().year()-5]

    const [currentCares, setCurrentCares] = useState([])
    const [showOldCares, setShowOldCares] = useState(false)
    const [notifyUsers, setNotifyUsers] = useState(false)
    
    const [currentTravels, setCurrentTravels] = useState([])
    const [showOldTravels, setShowOldTravels] = useState(false)

    const careStateOptions = [1,2].includes(admin.role) ? [
        { label: 'Vorbereitung', value: 3 },
        { label: 'Planung', value: 0 },
        { label: 'Storniert', value: 1 },
        { label: 'Bestätigt', value: 2 },
    ] : [
        { label: 'Vorbereitung', value: 3 }
    ]


    useEffect(() => {

        authAxios.get('users/' + (user.user_role === 1 ? 2 : 1))
        .then(response => {

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                let users = response.data.users.filter(u => u.konto === 4)
                setTargetUsers(users)
            }

        })
        .catch(error => {
            console.log(error)
        })

    }, [])

    useEffect(() => {
        setNotifyUsers(false)
    }, [editCareID])


    useEffect(() => {
        setCurrentCares(user.cares.filter(care => (moment(care.enddatum).add(1, 'day').isAfter(moment()) || !care.enddatum) && care.status !== 1))
    }, [user.cares])

    useEffect(() => {
        if(user.user_role === 2){ return }
        setCurrentTravels(user.travels.filter(travel => moment(travel.ankunft).isAfter(moment()) || !travel.ankunft ))
    }, [user.travels])


    function handleAddCare(){
        setAddCareModal(true)
        setAddCareInputs({
            status: 3,
            pb_user_id: user.user_role === 2 ? user.id : null,
            bk_user_id: user.user_role === 1 ? user.id : null,
            startdatum: '',
            startzeit: '',
            enddatum: '',
            endzeit: '',
            origin: 'manuell',
            changeStartdate: user.user_role === 1 ? false : true,
            autobreak: true,
        })
    }

    function handleAddCareCheck(){
        if(notifyUsers && addCareInputs.status !== 0){
            setAddCareModalCheck(true)
        }else{
            addCare()
        }
    }

    function handleEditCare(id){
        setEditCareModal(true)
        setEditCareID(id)
        let care = user.cares.find(care => care.id === id)
        setEditCareInputs({
            status: care.status,
            pb_user_id: care.pb_user_id,
            bk_user_id: care.bk_user_id,
            startdatum: care.startdatum,
            startzeit: care.startzeit,
            enddatum: care.enddatum,
            endzeit: care.endzeit,
            changeStartdate: user.user_role === 1 ? false : true,
            autobreak: care.autobreak,
        })
    }

    function handleUpdateCare(){
        if(notifyUsers && editCareInputs.status !== 0){
            setUpdateCareModal(true)
        }else{
            updateCare()
        }
    }

    function addCare(){
        
        setAddCareModalCheck(false)
        setLoading(true)
        
        authAxios.post('care', {...addCareInputs, notify: notifyUsers})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAddCareModal(false)
                setUser({
                    ...user,
                    startdatum: user.user_role === 2 ? response.data.startdatum : response.data.bk_startdatum,
                    cares: [response.data.care, ...user.cares],
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function cancelEditCare(){
        setEditCareModal(false)
        setEditCareID(null)
    }

    function updateCare(){
        
        setUpdateCareModal(false)
        setLoading(true)
        
        authAxios.put('care/' + editCareID, {...editCareInputs, notify: notifyUsers})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setEditCareModal(false)
                setEditCareID(null)
                setUser({
                    ...user,
                    startdatum: user.user_role === 2 ? response.data.startdatum : response.data.bk_startdatum,
                    cares: user.cares.map(c => c.id === editCareID ? {...c, ...response.data.care} : c)
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function handleDeleteCare(){
        
        let q = window.confirm('Diese Betreuung wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('care/' + editCareID)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: 'Gelöscht.' })
                    setEditCareModal(false)
                    setUser({
                        ...user,
                        cares: user.cares.filter(care => care.id !== editCareID)
                    })
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }


    function handleAddTravel(){
        setAddTravelInputs({
            user_id: user.id,
            abfahrtsdatum: '',
            abfahrtszeit: '',
            ankunftsdatum: '',
            ankunftszeit: '',
            abfahrtsadresse: defaultAdress(),
            ankunftsadresse: '',
            transferart: '',
            transfergesellschaft: '',
            transferid: '',
            kommentar: '',
        })
        setAddTravelModal(true)
    }

    function defaultAdress(){
        let adress = ''
        adress += user.strasse ? user.strasse : ''
        adress += user.hausnummer ? user.hausnummer : ''
        adress += '\n'
        adress += user.plz ? user.plz : ''
        adress += user.ort ? user.ort : ''
        adress += '\n'
        adress += user.land ? countries[user.land] : ''
        return adress
    }

    function handleEditTravel(id){
        setEditTravelID(id)
        let travel = user.travels.find(travel => travel.id === id)
        setEditTravelInputs({
            abfahrtsdatum: moment(travel.abfahrt).format('YYYY-MM-DD'),
            abfahrtszeit: moment(travel.abfahrt).format('HH:mm'),
            ankunftsdatum: moment(travel.ankunft).format('YYYY-MM-DD'),
            ankunftszeit: moment(travel.ankunft).format('HH:mm'),
            abfahrtsadresse: travel.abfahrtsadresse,
            ankunftsadresse: travel.ankunftsadresse,
            transferart: travel.transferart,
            transfergesellschaft: travel.transfergesellschaft,
            transferid: travel.transferid,
            kommentar: travel.kommentar,
        })     
        setEditTravelModal(true)
    }

    function addTravel(){
        
        setLoading(true)
        
        authAxios.post('travel', addTravelInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAddTravelModal(false)
                setUser({
                    ...user,
                    travels: [response.data.travel, ...user.travels],
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function updateTravel(){
        
        setEditTravelModal(false)
        setLoading(true)
        
        authAxios.put('travel/' + editTravelID, {...editTravelInputs})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setUser({
                    ...user,
                    travels: user.travels.map(travel => travel.id === editTravelID ? {...travel, ...response.data.travel} : travel)
                })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function handleDeleteTravel(){
        
        let q = window.confirm('Diese Reise wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('travel/' + editTravelID)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    setAlertMessage({ type: response.data.type, message: response.data.message })
                }else{
                    setAlertMessage({ type: response.data.type, message: 'Gelöscht.' })
                    setEditTravelModal(false)
                    setUser({
                        ...user,
                        travels: user.travels.filter(travel => travel.id !== editTravelID)
                    })
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }

    function loadMatching(){

        setLoading(true)

        authAxios.get('users/' + (user.user_role === 1 ? 2 : 1) + '/matching', {params: {compare_user: user.id}})
        .then(response => {

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                let users = response.data.users.filter(u => u.konto === 4 && u.status !== 2)
                setTargetUsers(users)
                setMatchingModal(true)
                setLoading(false)
            }

        })
        .catch(error => {
            console.log(error)
        })
        
    }


    function selectMatch(id){
        setMatchingModal(false)
        if(editCareID){
            setEditCareInputs({
                ...editCareInputs,
                [user.user_role === 1 ? 'pb_user_id' : 'bk_user_id']: id,
            })
        }else{
            setAddCareInputs({
                ...addCareInputs,
                [user.user_role === 1 ? 'pb_user_id' : 'bk_user_id']: id,
            })
        }
    }
    

    function handleSendCareInfo(id){
        setEditCareID(id)
        let empfaenger = user.user_role === 2 ? (user.contacts.length ? user.contacts[0].id : null) : user.id
        setCareInfoInputs({
            template: null,
            empfaenger_id: empfaenger,
            confirmation: true,
        })  
        setShowCareInfoModal(true)
    }

    function sendCareInfo(){

        setLoading(true)
            
        authAxios.post('care-message', {
            ...careInfoInputs,
            user_id: user.id,
            care_id: editCareID,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Nachricht gesendet.' })
                setShowCareInfoModal(false)
            }

        })
        .catch(error => {
            console.log(error.response)
        })

    }


    return (
        <div className="page-content">

            <Alert data={alertMessage} />
            
            <div className="window-row">
                <div className="col">

                    <Window name="userTimeline" title="Jahresübersicht" actions={
                        <div style={{marginLeft:'auto', marginRight: 4, width:100}}>
                            <Selectbox options={years.map(y => ({label: y, value: y}))} name="year" state={timeline} setState={setTimeline} />
                        </div>
                    }>

                        <CareTimeline user={user} year={timeline.year} />

                    </Window>

                </div>
            </div>
            
            <div className="window-row">
                <div className="col">

                    <Window name="userCares" title="Betreuungen" actions={
                        <div style={{display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center'}}>
                            {userBadges.user_cares !== 0 && <span className="count">{userBadges.user_cares}</span>}
                            <div style={{marginLeft:'auto'}}>
                                { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id, 'is_dead': user.todestag ? true : false }) ? (
                                    <button onClick={e => handleAddCare()}><FontAwesomeIcon icon={faPlus} /> Betreuungstermin erstellen</button>
                                ) : null }
                            </div>
                        </div>
                    }>

                        <Table name="tableUserCares" data={showOldCares ? user.cares : currentCares} headers={[
                            {
                                label: 'Status',
                                field: 'status',
                                type: 'carestatus',
                                size: 's',
                            },
                            {
                                label: 'Start',
                                field: 'startdatum',
                                type: 'date',
                                size: 's',
                            },
                            {
                                label: 'Ende',
                                field: 'enddatum',
                                type: 'date',
                                size: 's',
                            },
                            user.user_role === 2 ? {
                                label: 'Betreuungskraft',
                                field: 'bk_user_id',
                                type: 'user',
                                size: 'l',
                            } : {
                                label: 'Pflegebedürftige(r)',
                                field: 'pb_user_id',
                                type: 'user',
                                size: 'l',
                            },
                            {
                                label: 'Matching',
                                field: 'matching',
                                type: 'score',
                                size: 's',
                            },
                            user.user_role === 2 ? {
                                label: 'Bewertung PB',
                                field: 'pb_rating',
                                type: 'rating',
                                size: 'm',
                            } : {
                                label: 'Bewertung BK',
                                field: 'bk_rating',
                                type: 'rating',
                                size: 'm',
                            },
                            {
                                label: 'Ursprung',
                                field: 'origin',
                                type: 'origin',
                                size: 's',
                            },
                        ]}
                        onAction={handleSendCareInfo}
                        actionIcon={faEnvelope}
                        onEdit={handleEditCare}
                        editType="cares"
                        currentUser={user}
                        footerContent={
                            <label className="checkContainer">
                                <input type="checkbox" checked={showOldCares} onChange={() => setShowOldCares(!showOldCares)} /> <span>Vergangene/Stornierte Termine anzeigen</span>
                            </label>
                        } />

                    </Window>

                </div>
            </div>
            
            { user.user_role === 1 &&
            <div className="window-row">
                <div className="col">

                    <Window name="userTransfers" title="Reiseorganisation" actions={
                        <div style={{marginLeft:'auto'}}>
                            { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id, 'is_dead': user.todestag ? true : false }) ? (
                                <button onClick={e => handleAddTravel()}><FontAwesomeIcon icon={faPlus} /> Reise erstellen</button>
                            ) : null }
                        </div>
                    }>

                        <Table name="tableUserTravels" data={showOldTravels ? user.travels : currentTravels} headers={[
                            {
                                label: 'Abfahrt',
                                field: 'abfahrt',
                                type: 'datetime',
                                size: 'm',
                            },
                            {
                                label: 'Abfahrtsadresse',
                                field: 'abfahrtsadresse',
                                type: 'multitext',
                                size: 'l',
                            },
                            {
                                label: 'Ankunft',
                                field: 'ankunft',
                                type: 'datetime',
                                size: 'm',
                            },
                            {
                                label: 'Ankunftsadresse',
                                field: 'ankunftsadresse',
                                type: 'multitext',
                                size: 'l',
                            },
                            {
                                label: 'Transferart',
                                field: 'transferart',
                                type: 'transfertype',
                                size: 's',
                            },
                        ]}
                        onEdit={handleEditTravel}
                        footerContent={
                            <label className="checkContainer">
                                <input type="checkbox" checked={showOldTravels} onChange={() => setShowOldTravels(!showOldTravels)} /> <span>Vergangene Reisen anzeigen</span>
                            </label>
                        } />

                    </Window>

                </div>
            </div>
            }


            { addCareModal &&
                <Modal title="Betreuung erstellen" toggle={setAddCareModal} actions={
                    <div className="modal-actions">
                        { ![0,3].includes(addCareInputs.status) &&
                        <label className="checkContainer">
                            <input type="checkbox" checked={notifyUsers} onChange={() => setNotifyUsers(!notifyUsers)} /> <span>Benutzer benachrichtigen</span>
                        </label>
                        }
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddCareModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => handleAddCareCheck()}><FontAwesomeIcon icon={faCheck} /> Erstellen</button>
                        </div>
                    </div>
                }>
  
                    <div className="row">
                        <div className="col">

                            <div className="row">
                                <div className="col">
                                    <label>Status<br />
                                    <Selectbox options={careStateOptions} name="status" state={addCareInputs} setState={setAddCareInputs} />
                                    </label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">

                                    <label>{ user.user_role === 2 ? 'Betreuungskraft' : 'Pflegebedürftige Person'}<br />
                                    <Selectbox options={targetUsers.map(u => ({label: u.fullname, value: u.id}))} name={ user.user_role === 2 ? 'bk_user_id' : 'pb_user_id' } state={addCareInputs} setState={setAddCareInputs} />
                                    </label>

                                </div>
                            </div>

                        </div>
                        <div className="col">   

                                
                            <div className="row">
                                <div className="col">
                                    <label>Startdatum<br />
                                    <input type="date" value={addCareInputs.startdatum} onChange={e => setAddCareInputs({...addCareInputs, startdatum: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>Startzeit<br />
                                    <input type="time" value={addCareInputs.startzeit} onChange={e => setAddCareInputs({...addCareInputs, startzeit: e.target.value})} />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Enddatum<br />
                                    <input type="date" value={addCareInputs.enddatum} onChange={e => setAddCareInputs({...addCareInputs, enddatum: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>Endzeit<br />
                                    <input type="time" value={addCareInputs.endzeit} onChange={e => setAddCareInputs({...addCareInputs, endzeit: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">

                        <div className="col">
                            <button onClick={() => loadMatching()}><FontAwesomeIcon icon={faPeopleArrows} /> Matching-Suche</button>
                        </div>

                        { user.user_role === 1 ? (
                        <div className="col blueInfo autobreak">
                            <label className="checkContainer" style={{marginLeft: 12}}>
                                <input type="checkbox" checked={addCareInputs.autobreak} onChange={() => setAddCareInputs({...addCareInputs, autobreak: !addCareInputs.autobreak})} /> 
                                <span style={{color: '#161a1d' }}>Auto-Pause anhängen (21 Tage)</span>
                            </label>
                        </div>
                        ) : null }

                        {(addCareInputs.enddatum && addCareInputs.status === 2 && user.user_role === 2) ? (
                        <div className="col blueInfo">
                            <label className="checkContainer" style={{marginLeft: 12}}>
                                <input type="checkbox" checked={addCareInputs.changeStartdate} onChange={() => setAddCareInputs({...addCareInputs, changeStartdate: !addCareInputs.changeStartdate})} /> 
                                <span>Das neue Startdatum auf den <strong>{moment(addCareInputs.enddatum).format('DD.MM.YYYY')}</strong> verlegen</span>
                            </label>
                        </div>
                        ) : null}

                    </div>

                </Modal>
            }
          
          
            { addCareModalCheck &&
                <Modal title="Sicher?" toggle={setAddCareModalCheck} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddCareModalCheck(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => addCare()}><FontAwesomeIcon icon={faCheck} /> Änderungen veröffentlichen</button>
                        </div>
                    </div>
                }>
                    Möchten Sie diese Betreuung wirklich speichern?<br />
                    PB und BK werden beide sofort per Email und Push-Benachrichtigung über die Bestätigung informiert.
                </Modal>
            }

            { editCareModal &&
                <Modal title="Betreuung editieren" toggle={setEditCareModal} actions={
                    <div className="modal-actions">
                        { user.cares.find(c => c.id === editCareID).status === 3 && editCareInputs.status === 3 ? (                            
                            <button className="deleteLink" onClick={e => handleDeleteCare()}>Betreuung löschen</button>
                        ) : null }
                        { ![0,3].includes(editCareInputs.status) &&
                        <label className="checkContainer">
                            <input type="checkbox" checked={notifyUsers} onChange={() => setNotifyUsers(!notifyUsers)} /> <span>Benutzer benachrichtigen</span>
                        </label>
                        }
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => cancelEditCare()}>Abbrechen</button>
                            <button className="saveButton" onClick={e => handleUpdateCare()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                                  
                    <div className="row">
                        <div className="col">   

                            <div className="row">
                                <div className="col">
                                    <label>Status<br />
                                    <Selectbox options={careStateOptions} name="status" state={editCareInputs} setState={setEditCareInputs} />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>{ user.user_role === 2 ? 'Betreuungskraft' : 'Pflegebedürftige Person'}<br />
                                    <Selectbox options={targetUsers.map(u => ({label: u.fullname, value: u.id}))} name={ user.user_role === 2 ? 'bk_user_id' : 'pb_user_id' } state={editCareInputs} setState={setEditCareInputs} />
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="col">

                            <div className="row">
                                <div className="col">
                                    <label>Startdatum<br />
                                    <input type="date" value={editCareInputs.startdatum || ''} onChange={e => setEditCareInputs({...editCareInputs, startdatum: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>Startzeit<br />
                                    <input type="time" value={editCareInputs.startzeit || ''} onChange={e => setEditCareInputs({...editCareInputs, startzeit: e.target.value})} />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Enddatum<br />
                                    <input type="date" value={editCareInputs.enddatum || ''} onChange={e => setEditCareInputs({...editCareInputs, enddatum: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>Endzeit<br />
                                    <input type="time" value={editCareInputs.endzeit || ''} onChange={e => setEditCareInputs({...editCareInputs, endzeit: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        
                        <div className="col">
                            <button onClick={() => loadMatching()}><FontAwesomeIcon icon={faPeopleArrows} /> Matching-Suche</button>
                        </div>

                        { user.user_role === 1 ? (
                        <div className="col blueInfo autobreak">
                            <label className="checkContainer" style={{marginLeft: 12}}>
                                <input type="checkbox" checked={editCareInputs.autobreak} onChange={() => setEditCareInputs({...editCareInputs, autobreak: !editCareInputs.autobreak})} /> 
                                <span style={{color: '#161a1d' }}>Auto-Pause anhängen (21 Tage)</span>
                            </label>
                        </div>
                        ) : null }
                        
                        {( editCareInputs.status === 2 && user.user_role === 2) ? (
                        <div className="col blueInfo">
                            <label className="checkContainer" style={{marginLeft: 12}}>
                                <input type="checkbox" checked={editCareInputs.changeStartdate} onChange={() => setEditCareInputs({...editCareInputs, changeStartdate: !editCareInputs.changeStartdate})} /> 
                                <span>Das neue Startdatum auf den <strong>{moment(editCareInputs.status === 2 ? editCareInputs.enddatum : editCareInputs.startdatum).format('DD.MM.YYYY')}</strong> verlegen</span>
                            </label>
                        </div>                                
                        ) : null}

                    </div>

                </Modal>
            }
          
          
            { updateCareModal &&
                <Modal title="Sicher?" toggle={setUpdateCareModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setUpdateCareModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => updateCare()}><FontAwesomeIcon icon={faCheck} /> Änderungen veröffentlichen</button>
                        </div>
                    </div>
                }>
                    Möchten Sie diese Änderungen wirklich speichern?<br />
                    PB und BK werden beide sofort per Email und Push-Benachrichtigung über die Bestätigung bzw. Änderung informiert.
                </Modal>
            }


            { addTravelModal &&
                <Modal title="Reise erstellen" toggle={setAddTravelModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddTravelModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => addTravel()}><FontAwesomeIcon icon={faCheck} /> Erstellen</button>
                        </div>
                    </div>
                }>
  
                    <div className="row">
                        <div className="col">
                                
                            <div className="row">
                                <div className="col">
                                    <label>Abfahrtsdatum<br />
                                    <input type="date" value={addTravelInputs.abfahrtsdatum} onChange={e => setAddTravelInputs({...addTravelInputs, abfahrtsdatum: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>Abfahrtszeit<br />
                                    <input type="time" value={addTravelInputs.abfahrtszeit} onChange={e => setAddTravelInputs({...addTravelInputs, abfahrtszeit: e.target.value})} />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Abfahrtsadresse<br />
                                    <textarea rows={5} value={addTravelInputs.abfahrtsadresse} onChange={e => setAddTravelInputs({...addTravelInputs, abfahrtsadresse: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="col">

                            <div className="row">
                                <div className="col">
                                    <label>Transferart<br />
                                    <Selectbox options={[
                                        { label: 'Auto', value: 'car' },
                                        { label: 'Bus', value: 'bus' },
                                        { label: 'Zug', value: 'train' },
                                        { label: 'Flugzeug', value: 'plane' },
                                    ]} name="transferart" state={addTravelInputs} setState={setAddTravelInputs} />
                                    </label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Transfergesellschaft<br />
                                    <input type="text" value={addTravelInputs.transfergesellschaft} onChange={e => setAddTravelInputs({...addTravelInputs, transfergesellschaft: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Transfer-ID<br />
                                    <input type="text" value={addTravelInputs.transferid} onChange={e => setAddTravelInputs({...addTravelInputs, transferid: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="col">
                                
                            <div className="row">
                                <div className="col">
                                    <label>Ankunftsdatum<br />
                                    <input type="date" value={addTravelInputs.ankunftsdatum} onChange={e => setAddTravelInputs({...addTravelInputs, ankunftsdatum: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>Ankunftszeit<br />
                                    <input type="time" value={addTravelInputs.ankunftszeit} onChange={e => setAddTravelInputs({...addTravelInputs, ankunftszeit: e.target.value})} />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Ankunftsadresse<br />
                                    <textarea rows={5} value={addTravelInputs.enddatum} onChange={e => setAddTravelInputs({...addTravelInputs, ankunftsadresse: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Zusätzliche Informationen<br />
                            <textarea value={addTravelInputs.kommentar} onChange={e => setAddTravelInputs({...addTravelInputs, kommentar: e.target.value})} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }


            { editTravelModal &&
                <Modal title="Reise editieren" toggle={setEditTravelModal} actions={
                    <div className="modal-actions">
                        <button className="deleteLink" onClick={e => handleDeleteTravel()}>Reise löschen</button>
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditTravelModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => updateTravel()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
  
                    <div className="row">
                        <div className="col">
                                
                            <div className="row">
                                <div className="col">
                                    <label>Abfahrtsdatum<br />
                                    <input type="date" value={editTravelInputs.abfahrtsdatum} onChange={e => setEditTravelInputs({...editTravelInputs, abfahrtsdatum: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>Abfahrtszeit<br />
                                    <input type="time" value={editTravelInputs.abfahrtszeit} onChange={e => setEditTravelInputs({...editTravelInputs, abfahrtszeit: e.target.value})} />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Abfahrtsadresse<br />
                                    <textarea rows={5} value={editTravelInputs.abfahrtsadresse} onChange={e => setEditTravelInputs({...editTravelInputs, abfahrtsadresse: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="col">

                            <div className="row">
                                <div className="col">
                                    <label>Transferart<br />
                                    <Selectbox options={[
                                        { label: 'Auto', value: 'car' },
                                        { label: 'Bus', value: 'bus' },
                                        { label: 'Zug', value: 'train' },
                                        { label: 'Flugzeug', value: 'plane' },
                                    ]} name="transferart" state={editTravelInputs} setState={setEditTravelInputs} />
                                    </label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Transfergesellschaft<br />
                                    <input type="text" value={editTravelInputs.transfergesellschaft} onChange={e => setEditTravelInputs({...editTravelInputs, transfergesellschaft: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label>Transfer-ID<br />
                                    <input type="text" value={editTravelInputs.transferid} onChange={e => setEditTravelInputs({...editTravelInputs, transferid: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="col">
                                
                            <div className="row">
                                <div className="col">
                                    <label>Ankunftsdatum<br />
                                    <input type="date" value={editTravelInputs.ankunftsdatum} onChange={e => setEditTravelInputs({...editTravelInputs, ankunftsdatum: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>Ankunftszeit<br />
                                    <input type="time" value={editTravelInputs.ankunftszeit} onChange={e => setEditTravelInputs({...editTravelInputs, ankunftszeit: e.target.value})} />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Ankunftsadresse<br />
                                    <textarea rows={5} value={editTravelInputs.ankunftsadresse} onChange={e => setEditTravelInputs({...editTravelInputs, ankunftsadresse: e.target.value})} />
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Zusätzliche Informationen<br />
                            <textarea value={editTravelInputs.kommentar} onChange={e => setEditTravelInputs({...editTravelInputs, kommentar: e.target.value})} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }


            { showCareInfoModal &&
                <Modal title="Betreuungsinformationen senden" toggle={setShowCareInfoModal} classes={['small']} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setShowCareInfoModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={sendCareInfo}><FontAwesomeIcon icon={faEnvelope} /> Senden</button>
                        </div>
                    </div>
                }>
                    
                    <div className="row">
                        <div className="col">
                            <label>Template<br />
                            <Selectbox options={ systemTemplates && systemTemplates.filter(t => t.user_role === user.user_role).map(t => ({ label: t.name, value: t.id })) } name="template" state={careInfoInputs} setState={setCareInfoInputs} />
                            </label>
                        </div>
                    </div>
                    { user.user_role === 2 &&
                    <div className="row">
                        <div className="col">
                            <label>Empfänger<br />
                            <Selectbox options={user.contacts.map(contact => {
                                return { label: contact.fullname + ' (' + ContactType(contact.kategorie) + ')', value: contact.id }
                            })} name="empfaenger_id" state={careInfoInputs} setState={setCareInfoInputs} />
                            </label>
                        </div>
                    </div>
                    }
                    <label className="row checkContainer" style={{ alignItems: 'start', marginTop: 20 }}>
                        <div>
                            <input type="checkbox" checked={careInfoInputs.confirmation} onChange={() => setCareInfoInputs({ ...careInfoInputs, confirmation: !careInfoInputs.confirmation })} />
                        </div>
                        <div className="col">
                            <div>Bestätigung anfordern</div>
                            <div style={{ color: '#7B919D', fontSize: 12,  lineHeight: '16px' }}>Der Benutzer wird in der E-Mail aufgefordert die Information zu bestätigen. Feedback dazu ist auf dem Dashboard sichtbar.</div>
                        </div>
                    </label>

                </Modal>
            }


            { matchingModal &&
                <Modal title="Matching-Suche" toggle={setMatchingModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setMatchingModal(false)}>Abbrechen</button>
                        </div>
                    </div>
                }>
                    
                    <Table name="tableUserMatching" data={targetUsers} perPage={25} headers={[
                        {
                            field: 'status',
                            label: 'Status',
                            type: 'status',
                            size: 's',
                        },
                        {
                            field: 'fullname',
                            label: 'Name',
                            type: 'text',
                            size: 'l',
                        },
                        {
                            field: 'pflegegrad',
                            label: 'Pflegegrad',
                            type: 'carelevel',
                            size: 's',
                            profile: true,
                        },
                        {
                            field: 'geburtsdatum',
                            label: 'Alter',
                            type: 'age',
                            size: 'xs',
                            profile: true,
                        },
                        {
                            field: 'score',
                            label: 'Matching',
                            type: 'score',
                            size: 'xs',
                        },
                    ]} onEdit={selectMatch} editText="Auswählen" editIcon={faCheck} />

                </Modal>
            }


        </div>
    )

}

export default ProfileCaresScreen