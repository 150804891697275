import React, { useState, useEffect, useContext } from 'react'
import Placeholder from '../images/placeholder.png'
import '../App.css'
import { useParams, useHistory, NavLink, Switch, Route } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWheelchair, faPhone, faCog, faMinus, faUserTie, faCheck, faPen, faMinusCircle, faEye, faEyeSlash, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { Scrollbars } from 'react-custom-scrollbars'
import moment from 'moment'

import { AppContext } from '../scripts/AppContext'
import { DataContext } from '../scripts/DataContext'
import { UserContext } from '../scripts/UserContext'
import { authAxios } from '../scripts/Axios'
import { ContactType, ContractType, LanguageBar, Country } from '../scripts/Functions'
import { userMenu } from '../scripts/Menu'

import AccountStatusSelector from '../components/AccountStatusSelector'
import UserStatus from '../components/UserStatus'
import CareLevel from '../components/CareLevel'
import Rating from '../components/Rating'
import Media from '../components/Media'
import Modal from '../components/Modal'
import Alert from '../components/Alert'
import Selectbox from '../components/Selectbox'
import ProfileImage from '../components/ProfileImage'
import Tooltip from '../components/Tooltip';

function ProfileScreen() {

    const { id } = useParams()
    const history = useHistory()

    const basePath = '/user/' + id

    const [alertMessage, setAlertMessage] = useState(null)

    const { setLoading, setPageSubtitle } = useContext(AppContext)
    const { admins } = useContext(DataContext)
    const { user: admin, checkScope } = useContext(UserContext)

    const [user, setUser] = useState(null)
    const [showSettingsModal, setShowSettingsModal] = useState(false)
    const [showUserDeleteModal, setShowUserDeleteModal] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [deathDate, setDeathDate] = useState(null)
    const [editStartdateModal, setEditStartdateModal] = useState(false)
    const [editDeathdateModal, setEditDeathdateModal] = useState(false)
    const [saveDeathdateModal, setSaveDeathdateModal] = useState(false)
    const [assignUserModal, setAssignUserModal] = useState(false)
    const [assignUserInputs, setAssignUserInputs] = useState({
        assigned_to: null,
    })
    const [assignedAdmin, setAssignedAdmin] = useState(null)
    
    const [userBadges, setUserBadges] = useState({
        user_cares: 0,
        user_tasks_notices: 0,
        user_communication: 0,
        user_chat: 0,
    })
    
    const [settingsInputs, setSettingsInputs] = useState({
        invisible: 0,
        breaks: [],
    })
    let breakIndex = 0
    const [deleteBreaks, setDeleteBreaks] = useState([])

    function updateUserBadges(){
        
        authAxios.get('user-badges/' + id)
        .then(response => {

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setUserBadges(response.data.badges)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }


    useEffect(() => {

        setLoading(true)

        updateUserBadges()

        authAxios.get('user/' + id)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message }) // wird nicht gerendert weil user null ist
            }else{
                let u = response.data.user
                setPageSubtitle(u.user_role === 1 ? 'Betreuungskraft' : 'Pflegebedürftige(r)')
                setUser(u)
                setStartDate(u.startdatum)
                setSettingsInputs({
                    invisible: u.invisible,
                    breaks: u.breaks,
                })
                setAssignUserInputs({
                    assigned_to: parseInt(u.assigned_to),
                })                
            }

        })
        .catch(error => {
            console.log(error)
        })

    }, [id])

    useEffect(() => {
        if(!admins || !user) return
        setAssignedAdmin(admins.find(admin => admin.id == user.assigned_to))
    }, [admins, user])


    function saveAssignUser(){

        setLoading(true)
        
        authAxios.put('user/' + user.id, assignUserInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setAssignUserModal(false)
                setUser(prev => ({
                    ...prev,
                    assigned_to: response.data.user.assigned_to,
                }))
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
    }

    
    function showSettings(){
        setDeleteBreaks([])
        setSettingsInputs({
            ...settingsInputs,
            invisible: user.invisible,
            breaks: user.breaks,
        })
        setShowSettingsModal(true)
    }
    

    function addBreak(){
        if(!settingsInputs.breaks){
            setSettingsInputs({
                ...settingsInputs,
                breaks: [{
                    user_id: user.id,
                    von: '',
                    bis: '',
                }]
            })
        }
        else{
            setSettingsInputs({
                ...settingsInputs,
                breaks: [
                    ...settingsInputs.breaks,
                    {
                        user_id: user.id,
                        von: '',
                        bis: '',
                    }
                ]
            })
        }
    }


    function saveSettings(){

        if(emptyDates()){
            setAlertMessage({ type: 'error', message: 'Bitte füllen Sie alle Felder aus.' })
            return
        }

        setLoading(true)
        
        authAxios.put('user/' + user.id, {
            ...settingsInputs,
            deleteBreaks: deleteBreaks,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setShowSettingsModal(false)
                let newUser = {
                    ...user,
                    startdatum: response.data.user.startdatum,
                    invisible: response.data.user.invisible,
                    breaks: response.data.breaks,
                    //status: moment().isBetween(settingsInputs.pause_von, settingsInputs.pause_bis) ? 2 : user.status,
                }
                setUser(newUser)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    function emptyDates(){
        let somethingEmpty = false
        settingsInputs.breaks.map(b => {
            if(!b.von || !b.bis) somethingEmpty = true
        })
        return somethingEmpty
    }


    function deleteUser(){

        setLoading(true)

        authAxios.delete('user/' + id)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                
                let target = user.user_role === 1 ? 'bk' : 'pb'
                history.push('/users/' + target)

            }

        })
        .catch(error => {
            console.log(error.response)
        })

    }

    function downloadSetcard(){

        setLoading(true)

        authAxios.get('setcard/' + id, { responseType: 'blob', timeout: 30000})
        .then(response => {

            setLoading(false)
            
            let link = document.createElement('a')
            link.href = URL.createObjectURL(response.data)
            link.setAttribute('download', 'SH24_Setcard-' + id)
            document.body.appendChild(link)
            link.click()

        })
        .catch(error => {
            console.log(error.response)
        })

    }

    function handleEditStartdate(){
        setEditStartdateModal(true)
        setStartDate(user.startdatum)
    }
    
    function saveStartdate(){
        
        setLoading(true)
            
        authAxios.put('user/' + user.id, {startdatum: startDate})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditStartdateModal(false)
                setUser({...user, startdatum: response.data.user.startdatum})
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
        
    }

    function handleEditDeathdate(){
        setEditDeathdateModal(true)
        setDeathDate(user.todestag)
    }

    function checkDeathDate()
    {
        if(!deathDate){
            setAlertMessage({ type: 'error', message: 'Bitte geben Sie ein Datum ein.' })
            return
        }
        setSaveDeathdateModal(true)
    }
    
    function saveDeathdate(){
        
        setLoading(true)
            
        authAxios.post('user/' + user.id + '/death', {todestag: deathDate})
        .then(response => {

            setLoading(false)
            setSaveDeathdateModal(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditDeathdateModal(false)

                setTimeout(() => window.location.reload(), 1000) // reload, to automatically update user
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
        
    }

    function copyProfileLink(){
        navigator.clipboard.writeText(process.env.REACT_APP_URL + 'Profil?user_id=' + user.id)
        setAlertMessage({type: 'success', message: 'Link kopiert!' })
    }

    return user && (
        <div id="profile">

            <Alert data={alertMessage} />

            <div className="profile-sidebar">

                <div className="profile-sidebar-head">

                    <AccountStatusSelector user={user} setUser={setUser} />

                    <div style={{ marginLeft: 'auto' }}>
                        { user.invisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} color="#d6e1e9" /> }
                    </div>

                    { checkScope('editUser', { 'created_by': user.created_by, 'assigned_to': user.assigned_to, 'group_id': user.group_id, 'is_dead': user.todestag ? true : false }) ? (
                        <div className="settingsButton" onClick={() => showSettings()}>
                            <FontAwesomeIcon icon={faCog} />
                        </div>
                    ) : null }

                </div>

                <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page sidebarScrollFix">
                <div className="page-content">

                    <div className="media-grid">
                        { user.media && user.media.length > 0 ?
                            <Media flex="0 0 calc(50% - 1px)" file={user.media[0]} linkTo={basePath + '/media-documents'} /> :
                            <img src={Placeholder} />
                        }                        
                        { assignedAdmin ?
                            <div className="assignedUser noselect" onClick={() => checkScope('assignAdmin', { 'is_dead': user.todestag ? true : false }) ? setAssignUserModal(true) : null }>
                                <ProfileImage id={assignedAdmin.id} style={{ width: 66, borderRadius: 33 }} />
                                <span className="assignedUserName">
                                    { assignedAdmin.vorname } { assignedAdmin.nachname }
                                </span>
                                <small>{ assignedAdmin.groups.length > 0 ? assignedAdmin.groups[0].name : '' }</small>
                            </div>
                            :
                            <div className="assignedUser noselect" onClick={() => checkScope('assignAdmin', { 'is_dead': user.todestag ? true : false }) ? setAssignUserModal(true) : null}>
                                { checkScope('assignAdmin', { 'is_dead': user.todestag ? true : false }) ? <span><FontAwesomeIcon icon={faUserPlus} size="2x" style={{ marginBottom: 10 }} /><br />Benutzer<br />zuweisen</span> : <span>Kein Benutzer<br />zugewiesen</span> }
                            </div>
                        }
                    </div>
                    
                    <br />

                    <div className="profile-infotable">
                        <div className="row" style={{alignItems:'center'}}>
                            <div className="col">User-ID:</div>
                            <div className="col">
                                <div className="link" onClick={copyProfileLink} style={{ fontSize: 16, verticalAlign: 'bottom' }}>{ user.id }</div>
                            </div>
                        </div>
                        <div className="row" style={{alignItems:'center'}}>
                            <div className="col">Status:</div>
                            <div className="col"><UserStatus value={user.status} /></div>
                        </div>
                        <div className="row" style={{alignItems:'center'}}>
                            <div className="col">Bewertung:</div>
                            <div className="col"><Rating value={user.rating} /></div>
                        </div>
                        { user.user_role === 1 &&    
                        <div className="row" style={{alignItems:'center'}}>
                            <div className="col">Land:</div>
                            <div className="col"><Country iso={user.land} align="end" /></div>
                        </div>
                        }
                        { user.user_role === 1 ? (
                        <div className="row" style={{alignItems:'center'}}>
                            <div className="col">Verfügbar ab:</div>
                            <div className="col">
                                { user.startdatum ? moment(user.startdatum).format('DD.MM.YYYY') : '-'}
                            </div>
                        </div>
                        ) : (
                        <div className="row" style={{alignItems:'center'}}>
                            <div className="col">Startdatum:</div>
                            <div className="col">
                                { checkScope('editStartdate', { 'is_dead': user.todestag ? true : false }) ? (
                                    <div className="link" onClick={() => handleEditStartdate()}>
                                        <FontAwesomeIcon icon={faPen} style={{ fontSize: 11, marginRight: 5 }} />
                                        { user.startdatum ? moment(user.startdatum).format('DD.MM.YYYY') : '-'}
                                    </div>
                                ) : (
                                    <div>
                                        { user.startdatum ? moment(user.startdatum).format('DD.MM.YYYY') : '-'}
                                    </div>
                                ) }
                            </div>
                        </div>
                        ) }
                        { user.user_role === 2 &&
                        <div className="row" style={{alignItems:'center'}}>
                            <div className="col">Todes-/Kündigungstag:</div>
                            <div className="col">
                                { checkScope('editDeathdate', { 'is_dead': user.todestag ? true : false }) ? (
                                    <div className="link" onClick={() => handleEditDeathdate()}>
                                        <FontAwesomeIcon icon={faPen} style={{ fontSize: 11, marginRight: 5 }} />
                                        { user.todestag ? moment(user.todestag).format('DD.MM.YYYY') : '-'}
                                    </div>
                                ) : (
                                    <div>
                                        { user.todestag ? moment(user.todestag).format('DD.MM.YYYY') : '-'}
                                    </div>
                                ) }
                            </div>
                        </div>
                        }
                        { user.user_role === 2 &&
                        <div className="row" style={{alignItems:'center'}}>
                            <div className="col">Nettolohn:</div>
                            <div className="col">{user.wage ? user.wage + ' €' : '-'}</div>
                        </div>
                        }
                        <div className="row" style={{alignItems:'center'}}>
                            <div className="col">SET-Card:</div>
                            <div className="col"><a onClick={downloadSetcard}>Download</a></div>
                        </div>
                        { user.user_role === 1 &&
                        <div>
                            <div>Deutsche Sprachkenntnisse:</div>
                            <LanguageBar value={user.german_skills} />
                        </div>
                        }
                    </div>

                    <div className="profile-infotable">
                        <hr />
                        <div className="row">
                            <div className="col auto" style={{ paddingRight: 5 }}>
                                <FontAwesomeIcon icon={user.user_role === 1 ? faUserTie : faWheelchair} size="3x" color={ user.user_role === 1 ? '#dd328a' : '#8a9447' } />
                            </div>
                            <div className="col" style={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left' }}>
                                <div>
                                    <Tooltip text={user.user_role === 1 ? (
                                        <div>
                                            <ContractType contract="avd" /> = Arbeitsvertrag Deutsch<br />
                                            <ContractType contract="avp" /> = Arbeitsvertrag Polnisch<br />
                                            <ContractType contract="avk" /> = Arbeitsvertrag Kombi
                                        </div>
                                        ):(
                                        <div>
                                            <ContractType contract="dpfv" /> = Deutscher Pflegedienstvertrag<br />
                                            <ContractType contract="pv" /> = Polnischer Dienstleistungsvertrag<br />
                                            <ContractType contract="d+pv" /> = Deutscher & Polnischer Vertrag
                                        </div>
                                    )}>
                                        Vertrag <ContractType contract={user.contract} />
                                    </Tooltip>
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <span style={{ color: user.user_role === 1 ? '#dd328a' : '#8a9447' }}>{user.user_role === 1 ? 'Betreuungskraft' : 'Pflegebedürftige(r)' }</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    { user.profiles && user.profiles.map((profile, index) => { 
                        return (
                            <div key={index} className="profile-infotable">
                                <hr />
                                {user.user_role === 1 ? (
                                    <div className="profileDesc" style={{ color:'#008cdb' }}>
                                        <FontAwesomeIcon icon={faUserTie} />
                                        Betreuungskraft
                                    </div>
                                ) : (
                                    <div className="profileDesc" style={{ color:'#008cdb' }}>
                                        <FontAwesomeIcon icon={faWheelchair} />
                                        Zu betreuende Person {user.profiles.length > 1 ? index + 1 : null}
                                    </div>
                                )}
                                <div className="profileName">
                                    {profile.fullname}
                                </div>
                                {user.user_role === 2 ? (
                                <div className="row">
                                    <div className="col">Pflegegrad:</div>
                                    <div className="col">
                                        { profile.pflegegrad && <CareLevel value={profile.pflegegrad} /> }
                                    </div>
                                </div>
                                ) : null}
                                <div className="row">
                                    <div className="col">Alter:</div>
                                    <div className="col">{ profile.geburtsdatum ? moment().diff(moment(profile.geburtsdatum), 'years') : ''}</div>
                                </div>
                                <div className="row">
                                    <div className="col">Größe:</div>
                                    <div className="col">{profile.groesse} cm</div>
                                </div>
                                <div className="row">
                                    <div className="col">Gewicht:</div>
                                    <div className="col">{profile.gewicht} kg</div>
                                </div>
                                <div className="row">
                                    <div className="col">Raucher:</div>
                                    <div className="col">{profile.raucher === 112 ? 'Ja' : 'Nein'}</div>
                                </div>
                                { checkScope('viewPBContacts', { 'user_role': user.user_role }) &&
                                <div className="row">
                                    <div className="col">Telefon:</div>
                                    <div className="col">{ user.telefon }</div>
                                </div>
                                }
                            </div>
                        )
                    })
                    }

                    { user.description !== null && user.description !== '' &&
                    <div className="profile-infotable">
                        <hr />
                        <div className="row">
                            <div className="col" style={{textAlign: 'left', fontWeight: 400, whiteSpace: 'initial'}}>
                                <strong>Freitext:</strong><br />
                                { user.description }
                            </div>
                        </div>
                    </div>
                    }

                    { user.contacts && checkScope('viewPBContacts', { 'user_role': user.user_role }) && user.contacts.map(contact => {
                        return (
                            <div key={contact.id} className="profile-infotable">
                                <hr />
                                <div className="profileDesc">
                                    <FontAwesomeIcon icon={faPhone} />
                                    { ContactType(contact.kategorie) }
                                </div>
                                <div className="profileName" style={{fontSize:16}}>{contact.fullname}</div>
                                <div className="row">
                                    <div className="col">Telefon:</div>
                                    <div className="col">{contact.telefon}</div>
                                </div>
                            </div>
                        )
                    })}
                
                </div>
                </Scrollbars>

            </div>

            <div className="profile-content">

                <div className="profile-tabs">
                    {
                    userMenu.map((el, index) => {
                        let allowedRoles = user.user_role === 1 ? el.allowedRoles.bk : el.allowedRoles.pb
                        return (el.inMenu && allowedRoles.includes(admin.role)) ? (   
                            <NavLink key={index} className="tab" exact={el.exact} to={basePath + el.path} activeStyle={{color: '#fff', backgroundColor: '#008cdb'}}>
                                <FontAwesomeIcon icon={el.icon} />
                                <span>{el.title}</span>
                                { userBadges[el.slug] ? (<span className="count">{ userBadges[el.slug] }</span>) : null }
                            </NavLink>
                        ) : null
                    })
                    }
                </div>

                <Switch>
                {
                userMenu.map((el, index) => {
                    let allowedRoles = user.user_role === 1 ? el.allowedRoles.bk : el.allowedRoles.pb
                    return allowedRoles.includes(admin.role) ? (
                        <Route key={index} exact={el.exact} path={basePath + el.path}>
                            <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page offset dark-scrollbar">
                                {el.screen !== null ? <el.screen user={user} setUser={setUser} userBadges={userBadges} updateUserBadges={updateUserBadges} /> : 'No screen component found.'}
                            </Scrollbars>
                        </Route>
                    ) : (
                        <Route key={index} exact={el.exact} path={basePath + el.path}>
                            <Scrollbars autoHide={true} autoHideDuration={250} autoHideTimeout={500} className="page offset dark-scrollbar"></Scrollbars>
                        </Route>
                    )
                })
                }              
                </Switch>

            </div>

            { editStartdateModal &&
                <Modal title="Startdatum editieren" toggle={setEditStartdateModal} classes={['small']} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditStartdateModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveStartdate()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                                        
                    <div className="row">
                        <div className="col">
                            <label>Startdatum<br />
                            <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }
            
            { editDeathdateModal &&
                <Modal title="Todes-/Kündigungstag" toggle={setEditDeathdateModal} classes={['small']} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditDeathdateModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => checkDeathDate()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                                        
                    <div className="row">
                        <div className="col">
                            <label>Todes-/Kündigungstag<br />
                            <input type="date" value={deathDate} onChange={e => setDeathDate(e.target.value)} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }

            { showSettingsModal &&
                <Modal title="Benutzer-Einstellungen" toggle={setShowSettingsModal} classes={['small']} actions={
                    <div className="modal-actions">
                        { admin.role == 1 && <button className="deleteLink" onClick={e => setShowUserDeleteModal(true)}>Benutzer löschen</button> }
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setShowSettingsModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveSettings()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    <div>
                        <div className="subtitle">Sichtbarkeit</div>
                        <label className="row checkContainer" style={{ alignItems: 'start', marginBottom: 20 }}>
                            <div>
                                <input type="checkbox" checked={settingsInputs.invisible} onChange={e => setSettingsInputs({...settingsInputs, invisible: e.target.checked ? 1 : 0})} />
                            </div>
                            <div className="col">
                                <div>User verstecken</div>
                                <div style={{ color: '#7B919D', fontSize: 12,  lineHeight: '16px' }}>Wenn der User versteckt ist, wird er nicht mehr in der App angezeigt.</div>
                            </div>
                        </label>
                    </div>
                    
                    <div>
                        <div className="subtitle">Pausen</div>
                        <div className="breakList">

                            { settingsInputs.breaks && settingsInputs.breaks.map((userBreak, index) => {
                                if(moment(userBreak.bis).isSameOrAfter(moment()) || !userBreak.von || !userBreak.bis || !userBreak.id){
                                    breakIndex++
                                    return (                                
                                        <div key={index} className="row break">
                                            <div className="breakIndex noSelect">
                                                <label><span>#</span>{breakIndex}</label>
                                            </div>
                                            <div>
                                                <label>Von</label>
                                            </div>
                                            <div className="col">
                                                <input type="date" value={userBreak.von} onChange={e => setSettingsInputs({...settingsInputs, breaks: settingsInputs.breaks.map((b, i) => i == index ? { ...b, von: e.target.value } : b )})} />
                                            </div>
                                            <div>
                                                <label>Bis</label>
                                            </div>
                                            <div className="col">
                                                <input type="date" value={userBreak.bis} onChange={e => setSettingsInputs({...settingsInputs, breaks: settingsInputs.breaks.map((b, i) => i == index ? { ...b, bis: e.target.value } : b )})} />
                                            </div>
                                            <div style={{ marginLeft: 'auto', flexShrink: 0 }}>
                                                <button className="deleteButton" onClick={e => {
                                                    if(userBreak.hasOwnProperty('id')) setDeleteBreaks([...deleteBreaks, userBreak.id])
                                                    setSettingsInputs({...settingsInputs, breaks: settingsInputs.breaks.filter((b, i) => i != index)})
                                                }}>
                                                    <FontAwesomeIcon icon={faMinusCircle} />
                                                </button>   
                                            </div>
                                        </div>
                                    )
                                }
                                else return null
                            })}

                        </div>

                        <div className="row">
                            <button className="outlineButton" onClick={e => addBreak()}>Pause hinzufügen</button>
                        </div>

                    </div>


                </Modal>
            }

            { showUserDeleteModal &&
                <Modal title="Sicher?" toggle={setShowUserDeleteModal} classes={['small']} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setShowUserDeleteModal(false)}>Abbrechen</button>
                            <button className="deleteButton" onClick={e => deleteUser()}><FontAwesomeIcon icon={faMinus} /> Löschen</button>
                        </div>
                    </div>
                }>
                    Möchten Sie diesen Benutzer wirklich löschen?<br />
                    Die Daten werden unwiderruflich gelöscht.
                </Modal>
            }
            
            { saveDeathdateModal &&
                <Modal title="Sicher?" toggle={setSaveDeathdateModal} classes={['small']} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setSaveDeathdateModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveDeathdate()}><FontAwesomeIcon icon={faCheck} /> Ja</button>
                        </div>
                    </div>
                }>
                    Sind Sie sicher? Anschließend können nur noch Admins Änderungen an diesem User vornehmen.
                </Modal>
            }

            { assignUserModal &&
                <Modal title="Benutzer zuweisen" toggle={setAssignUserModal} classes={['small']} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAssignUserModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAssignUser()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                    
                    <div className="row">
                        <div className="col">
                            <label>Benutzer<br />
                            <Selectbox options={admins.filter(admin => admin.konto != 0).map(admin => {
                                return { label: admin.vorname + ' ' + admin.nachname, value: admin.id }
                            })} name="assigned_to" state={assignUserInputs} setState={setAssignUserInputs} />
                            </label>
                        </div>
                    </div>

                </Modal>
            }

        </div>
    )

}

export default ProfileScreen