import React, { useContext, useState, useEffect } from 'react'
import '../../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'
import Dropzone from 'react-dropzone'

import { authAxios } from '../../scripts/Axios'
import { AppContext } from '../../scripts/AppContext'
import { DataContext } from '../../scripts/DataContext'

import Window from '../../components/Window'
import Table from '../../components/Table'
import Modal from '../../components/Modal'
import Selectbox from '../../components/Selectbox'
import Document from '../../components/Document'
import Alert from '../../components/Alert'

function CommunicationSettingsScreen() {

    const { setLoading } = useContext(AppContext)
    const { contactTypes } = useContext(DataContext)

    const [alertMessage, setAlertMessage] = useState(null)

    const [templates, setTemplates] = useState([])

    const [addTemplateModal, setAddTemplateModal] = useState(false)
    const [addTemplateInputs, setAddTemplateInputs] = useState(null)

    const [template, setTemplate] = useState('')   
    const [editTemplate, setEditTemplate] = useState(null)
    const [editTemplateModal, setEditTemplateModal] = useState(false)
    const [editTemplateInputs, setEditTemplateInputs] = useState(null)

    const ckConfig = {
        toolbar: [ 'heading', 'bold', 'italic', 'link', '|', 'numberedList', 'bulletedList', 'insertTable', '|', 'undo', 'redo' ]
    }

    useEffect(() => {

        setLoading(true)

        authAxios.get('templates')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setTemplates(response.data.templates)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }, [])


    useEffect(() => {

        setEditTemplateInputs({
            ...editTemplateInputs,
            template: template,
        })

    }, [template])


    function handleAddTemplate(){
        setAddTemplateModal(true)
        setAddTemplateInputs({
            name: '',
            betreff: '',
            absender: '',
            smtp: 'smtp',
            empfaenger: null,
            anhang: [],
            template: '',
        })
    }

    function saveAddTemplate(){
        
        setLoading(true)

        authAxios.post('template', addTemplateInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setAddTemplateModal(false)
                setTemplates([
                    ...templates,
                    response.data.template,
                ])
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
    }


    function handleEditTemplate(id){
        setEditTemplateModal(true)
        let template = templates.find(t => t.id === id)
        setEditTemplate(template)
        setEditTemplateInputs({
            name: template.name,
            betreff: template.betreff,
            absender: template.absender,
            smtp: template.smtp,
            empfaenger: template.empfaenger,
            anhang: template.anhang,
            template: template.template,
        })
    }


    function saveEditTemplate(){
        
        setLoading(true)

        authAxios.put('template/' + editTemplate.id, editTemplateInputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
                setEditTemplateModal(false)
                let updatedTemplate = response.data.template                
                updatedTemplate.sender_mail = updatedTemplate.absender + (updatedTemplate.smtp == 'smtp' ? '@senihelp24.de' : '@senihelp24.pl')
                setTemplates(templates.map(t => t.id === updatedTemplate.id ? updatedTemplate : t))
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
    }


    function handleDeleteTemplate(){
        
        let q = window.confirm('Dieses Template wirklich löschen?')
        if(q){

            setLoading(true)
            
            authAxios.delete('template/' + editTemplate.id)
            .then(response => {
    
                setLoading(false)
    
                if(response.data.type === 'error'){
                    alert(response.data.message)
                }else{
                    setTemplates(templates.filter(t => t.id !== editTemplate.id))
                    setEditTemplateModal(false)
                    alert(response.data.message)
                }
    
            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

    }

    
    function handleUpload(files, action){

        setLoading(true)

        let data = new FormData()
        files.map((file, index) => {
            data.append('files[' + index + ']', file)
        })
        
        authAxios.post('admin-upload', data)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                if(action === 'add'){
                    setAddTemplateInputs({
                        ...addTemplateInputs,
                        anhang: [
                            ...addTemplateInputs.anhang,
                            ...response.data.uploads,
                        ],
                    })
                }else if(action === 'edit'){
                    setEditTemplateInputs({
                        ...editTemplateInputs,
                        anhang: [
                            ...editTemplateInputs.anhang,
                            ...response.data.uploads,
                        ],
                    })
                }
            }

        })
        .catch(error => {
            console.log(error)
        })

    }
    
    function removeUpload(id){
        
        setEditTemplateInputs({
            ...editTemplateInputs,
            anhang: editTemplateInputs.anhang.filter(upload => upload.id !== id),
        })

    }
    
    function deleteUpload(id){

        setLoading(true)
        
        authAxios.delete('admin-upload/' + id)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                alert(response.data.message)
            }else{
                setAddTemplateInputs({
                    ...addTemplateInputs,
                    anhang: addTemplateInputs.anhang.filter(upload => upload.id !== id),
                })
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    return (
        <div className="page-content">

            <Alert data={alertMessage} />

            <div className="window-row">

                <div className="col">

                <Window name="settingsTemplates" title={templates.length + ' Nachrichten-Templates'} actions={
                    <div style={{marginLeft:'auto'}}>
                        <button onClick={e => handleAddTemplate()}><FontAwesomeIcon icon={faPlus} /> Template erstellen</button>
                    </div>
                }>

                        <Table name="tableTemplates" data={templates} headers={[
                            {
                                field: 'name',
                                label: 'Name',
                                type: 'text',
                                size: 'xl',
                            },
                            {
                                field: 'betreff',
                                label: 'Betreff',
                                type: 'text',
                                size: 'xl',
                            },
                            {
                                field: 'sender_mail',
                                label: 'Absender',
                                type: 'text',
                                size: 'm',
                            },
                            {
                                field: 'empfaenger',
                                label: 'Auto-Empfänger',
                                type: 'contactType',
                                size: 'm',
                            },
                            {
                                field: 'anhang',
                                label: 'Anhänge',
                                type: 'attachments',
                                size: 's',
                            },
                        ]} onEdit={handleEditTemplate} />

                    </Window>

                </div>

            </div>

            
            { addTemplateModal &&
                <Modal title="Template erstellen" toggle={setAddTemplateModal} actions={
                    <div className="modal-actions">
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setAddTemplateModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveAddTemplate()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                                   
                    <div className="row">
                        <div className="col">
                            <label>Name<br />
                            <input type="text" value={addTemplateInputs.name} onChange={e => setAddTemplateInputs({...addTemplateInputs, name: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Betreff<br />
                            <input type="text" value={addTemplateInputs.betreff} onChange={e => setAddTemplateInputs({...addTemplateInputs, betreff: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row" style={{margin: 0}}>
                                <div className="col">
                                    <label>Absender<br />
                                        <input type="text" value={addTemplateInputs.absender} onChange={e => setAddTemplateInputs({...addTemplateInputs, absender: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>&nbsp;<br />
                                        <Selectbox options={[{ label: '@senihelp24.de', value: 'smtp' }, { label: '@senihelp24.pl', value: 'smtp_pl' }]} name="smtp" state={addTemplateInputs} setState={setAddTemplateInputs} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <label>Empfänger<br />
                            <Selectbox options={contactTypes.map(ct => {
                                return { label: ct.name, value: ct.id }
                            })} name="empfaenger" state={addTemplateInputs} setState={setAddTemplateInputs} />
                            </label>
                        </div>
                    </div>
                    <div>
                        <label>Nachricht</label>
                        <CKEditor
                            config={ckConfig}
                            editor={ BalloonEditor }
                            data={ addTemplateInputs.template }
                            onChange={(event, editor) => {
                                setAddTemplateInputs({
                                    ...addTemplateInputs,
                                    template: editor.getData(),
                                })
                            }}
                        />
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Anhänge</label>
                            <div className="document-list" style={{marginTop:10}}>
                            { addTemplateInputs.anhang &&
                                addTemplateInputs.anhang.map((document, index) => <Document key={index} index={index} file={document} editable={true} handleDelete={deleteUpload} />)
                            }
                            </div>
                            <div className="uploader">                      
                                <Dropzone onDrop={acceptedFiles => handleUpload(acceptedFiles, 'add')}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>Hier klicken oder Dateien hineinziehen</p>
                                            <p>
                                                <small>Erlaubte Formate: jpg, png, pdf, doc, docx, xls, xlsx</small>
                                            </p>
                                        </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                        </div>
                    </div>

                </Modal>
            }

            
            { editTemplateModal &&
                <Modal title="Template editieren" toggle={setEditTemplateModal} actions={
                    <div className="modal-actions">
                        { editTemplate.system == 0 &&
                            <button className="deleteLink" onClick={e => handleDeleteTemplate()}>Template löschen</button>
                        }
                        <div style={{marginLeft:'auto'}}>
                            <button className="cancelButton" onClick={e => setEditTemplateModal(false)}>Abbrechen</button>
                            <button className="saveButton" onClick={e => saveEditTemplate()}><FontAwesomeIcon icon={faCheck} /> Speichern</button>
                        </div>
                    </div>
                }>
                                   
                    <div className="row">
                        <div className="col">
                            <label>Name<br />
                            <input type="text" value={editTemplateInputs.name} onChange={e => setEditTemplateInputs({...editTemplateInputs, name: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Betreff<br />
                            <input type="text" value={editTemplateInputs.betreff} onChange={e => setEditTemplateInputs({...editTemplateInputs, betreff: e.target.value})} />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row" style={{margin: 0}}>
                                <div className="col">
                                    <label>Absender<br />
                                        <input type="text" value={editTemplateInputs.absender} onChange={e => setEditTemplateInputs({...editTemplateInputs, absender: e.target.value})} />
                                    </label>
                                </div>
                                <div className="col">
                                    <label>&nbsp;<br />
                                        <Selectbox options={[{ label: '@senihelp24.de', value: 'smtp' }, { label: '@senihelp24.pl', value: 'smtp_pl' }]} name="smtp" state={editTemplateInputs} setState={setEditTemplateInputs} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <label>Empfänger<br />
                            <Selectbox options={contactTypes.map(ct => {
                                return { label: ct.name, value: ct.id }
                            })} name="empfaenger" state={editTemplateInputs} setState={setEditTemplateInputs} />
                            </label>
                        </div>
                    </div>
                    <div>
                        <label>Nachricht</label>
                        <CKEditor
                            config={ckConfig}
                            editor={ BalloonEditor }
                            data={ editTemplateInputs.template }
                            onChange={(event, editor) => {
                                setTemplate(editor.getData())
                            }}
                        />
                    </div>
                    { !editTemplate.system &&
                    <div className="row">
                        <div className="col">
                            <label>Anhänge</label>                            
                            <div className="document-list" style={{marginTop:10}}>
                            { editTemplateInputs.anhang &&
                                editTemplateInputs.anhang.map((document, index) => <Document key={index} index={index} file={document} editable={true} handleDelete={removeUpload} />)
                            }
                            </div>
                            <div className="uploader">                      
                                <Dropzone onDrop={acceptedFiles => handleUpload(acceptedFiles, 'edit')}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>Hier klicken oder Dateien hineinziehen</p>
                                            <p>
                                                <small>Erlaubte Formate: jpg, png, pdf, doc, docx, xls, xlsx</small>
                                            </p>
                                        </div>
                                    </section>
                                )}
                                </Dropzone>
                            </div>
                        </div>
                    </div>
                    }

                </Modal>
            }

        </div>
    )

}

export default CommunicationSettingsScreen