import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import '../../App.css'

import { AppContext } from '../../scripts/AppContext'
import { authAxios } from '../../scripts/Axios'

import Window from '../../components/Window'
import Selectbox from '../../components/Selectbox'
import Alert from '../../components/Alert'

function HolidaySettingsScreen() {

    const { setLoading } = useContext(AppContext)
    const [alertMessage, setAlertMessage] = useState(null)
    
    const [holidays, setHolidays] = useState([])

    const years = Array.from(Array(5)).map((y, i) => moment().year() - i + 1)
    const defaultMonths = {
        januar: 0,
        februar: 0,
        maerz: 0,
        april: 0,
        mai: 0,
        juni: 0,
        juli: 0,
        august: 0,
        september: 0,
        oktober: 0,
        november: 0,
        dezember: 0,
    }
    const [currentHoliday, setCurrentHoliday] = useState({ jahr: moment().year() })

    useEffect(() => {

        setLoading(true)

        authAxios.get('holidays')
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setHolidays(response.data.holidays)

                let current = response.data.holidays.find(h => h.jahr == moment().year())
                if(current)
                    setCurrentHoliday(current)
                else
                    setCurrentHoliday({ jahr: moment().year(), ...defaultMonths })
            }

        })
        .catch(error => {
            console.log(error)
        })

    }, [])


    useEffect(() => {

        if(!currentHoliday.hasOwnProperty('jahr')) return

        let holiday = holidays.find(h => h.jahr == currentHoliday.jahr)
        if(holiday)
            setCurrentHoliday(holiday)
        else
            setCurrentHoliday({ jahr: currentHoliday.jahr, ...defaultMonths })

    }, [currentHoliday.jahr])


    function saveCurrentHoliday()
    {
        setLoading(true)

        authAxios.put('holidays', currentHoliday)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                setAlertMessage({ type: response.data.type, message: response.data.message })
            }else{
                setHolidays(response.data.holidays)

                setAlertMessage({ type: response.data.type, message: 'Gespeichert!' })
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
    }

    const HolidaysMonthsView = () =>
    {
        return (
            <div className="container">

                <div className="row">
                    <div className="col">
                        <label>Januar</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.januar } onChange={e => setCurrentHoliday({...currentHoliday, januar: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Februar</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.februar } onChange={e => setCurrentHoliday({...currentHoliday, februar: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>März</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.maerz } onChange={e => setCurrentHoliday({...currentHoliday, maerz: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>April</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.april } onChange={e => setCurrentHoliday({...currentHoliday, april: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Mai</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.mai } onChange={e => setCurrentHoliday({...currentHoliday, mai: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Juni</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.juni } onChange={e => setCurrentHoliday({...currentHoliday, juni: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Juli</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.juli } onChange={e => setCurrentHoliday({...currentHoliday, juli: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>August</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.august } onChange={e => setCurrentHoliday({...currentHoliday, august: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>September</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.september } onChange={e => setCurrentHoliday({...currentHoliday, september: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Oktober</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.oktober } onChange={e => setCurrentHoliday({...currentHoliday, oktober: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>November</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.november } onChange={e => setCurrentHoliday({...currentHoliday, november: parseInt(e.target.value) })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Dezember</label>
                    </div>
                    <div className="col">
                        <input type="number" min="0" step="1" value={ currentHoliday.dezember } onChange={e => setCurrentHoliday({...currentHoliday, dezember: parseInt(e.target.value) })} />
                    </div>
                </div>

                <div className="row">
                    <div style={{ marginLeft: 'auto', marginTop: 10 }}>
                        <button onClick={saveCurrentHoliday}>Speichern</button>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className="page-content">
           
           <Alert data={alertMessage} />

            <div className="window-row">

                <div className="col" style={{ flex: '.5 1' }}>

                    <Window name="settingsHolidays" title="Feiertage" actions={
                        <div style={{marginLeft:'auto', marginRight: 4, width:100}}>
                            <Selectbox options={years.map(y => ({label: y, value: y}))} name="jahr" state={currentHoliday} setState={setCurrentHoliday} />
                        </div>
                    }>

                        { currentHoliday && <HolidaysMonthsView /> }

                    </Window>

                </div>
                <div className="col">


                </div>

            </div>

        </div>
    )

}

export default HolidaySettingsScreen